<template lang="pug">
  v-app
    //- .d-flex(v-if="!mobileView" style="width: 90%" class="m-auto")
    //-   img(:src="require(`@/assets/images/moment/moment1_${$i18n.locale}.png`)" class="m-auto" style="width: 60%")
    //-   img(:src="require(`@/assets/images/moment/moment2_${$i18n.locale}.png`)" class="m-auto" style="width: 40%")
    .d-flex(:class="mobileView ? 'flex-column' : 'flex-row'" :style="mobileView ? 'width: 90%' : 'width: 80%'" class="m-auto")
      .moment-container
        .top-image
          img.mt-2(src="@/assets/images/new/momentbersamaibu.png")
        .d-flex(:class="mobileView ? 'flex-column' : 'flex-row'")
          .moment
            h3.m-4 {{ $t("moment.step1") }}
            img(src="@/assets/images/new/moments-ibu-01.png")
            .m-4(v-html="$t('moment.label1')")
          .moment
            h3.m-4 {{ $t("moment.step2") }}
            img(src="@/assets/images/new/moments-ibu-02.png")
            .m-4(v-html="$t('moment.label2')")
          .moment
            h3.m-4 {{ $t("moment.step3") }}
            img(src="@/assets/images/new/moments-ibu-03.png")
            .m-4(v-html="$t('moment.label3')")
      img(:src="require(`@/assets/images/moment/moment2_${$i18n.locale}.png`)" class="m-auto" :style="mobileView ? 'width: 100%; margin-top: 2rem !important' : 'width: 35%; margin-left: 2rem !important'")
    .d-flex(:style="mobileView ? 'width: 90%' : 'width: 80%'" class="m-auto mt-8" :class="mobileView ? 'flex-column' : ''")
      .d-flex.flex-column(:style="mobileView ? 'width: 100%' : 'width: 60%'")
        b.label.ml-4 {{ $t("moment.tnc") }}
        v-expansion-panels(
          accordion
          focusable
        )
          v-expansion-panel(
            v-for="(item,i) in tnc"
            :key="i"
          )
            v-expansion-panel-header(disable-icon-rotate)
              b {{ item.title }}
              template(v-slot:actions)
                v-icon(color="secondary") mdi-plus
            v-expansion-panel-content(style="padding: 16px 24px;" v-html="item.text")
      img(:src="require(`@/assets/images/moment/moment3.png`)" class="m-auto" :style="mobileView ? 'width: 100%' : 'width: 35%'")
      div.my-4
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      tnc: [
        {
          title: "1. ORGANISER & ELIGIBILITY",
          text:
            "<ul><li class='mb-1'><b>The Libresse “#MomentsBersamaIbu”</b> is organised by Vinda Marketing (M) Sdn. Bhd. (199401027546/313228-T) <b>(“the Organiser”)</b>\
            of <em>No. 1, Persiaran Bukit Raja Kayangan/KU5 Zon Perindustrian, Bandar Bukit Raja, 41050 Klang, Selangor</em>.</li> \
            <li class='mb-1'>This Contest is open to all participants who have joined Libresse’s Know Your V online school program at the time of the Commencement Date (as defined in paragraph 2.1) of the Contest (the “Contestant”).</li> \
            <li class='mb-1'>The organiser and the parties involved in this Contest shall not be held liable in any way for any delays,</li> \
            <li class='mb-1'>non-deliveries and/or interruptions of redemption entries sent and/or received.</li></ul>"
        },
        {
          title: "2. CONTEST PERIOD",
          text:
            "<ul><li class='mb-1'>The Contest period commences on <b>2nd May 2023 (“Commencement Date”)</b> and ends on <b>1st October 2023 (“Closing Date”)</b>\
              . The period of the Commencement Date and the Closing Date shall be referred as the <em>“Contest Period”</em>.</li> \
            <li class='mb-1'>The Organiser reserves the right to extend and/or shorten the Contest Period at any time at its sole discretion without prior notice.</li> \
            <li class='mb-1'>Unless an extended Contest Period is announced by the Organiser (“Extended Period”), any participation or points collected by the Contestant in respect of the Contest (“Entries”) which is received or recorded after 11.59pm on the Closing Date will not be accepted by the Organiser.</li> \
            <li class='mb-1'>Any participation in the Contest during the Extended Period (if any) shall be governed by the terms and conditions set herein which shall remain in full force and effect.</li></ul>"
        },
        {
          title: "3. CONTEST CRITERIA & MECHANICS",
          text:
            "<ul><li class='mb-1'>To participate in this Contest and be eligible for a chance to win, the Contestant must join Libresse’s Know Your V online school program. \
              Contestant is required to follow Libresse Malaysia’s Instagram page (<a href='https://www.instagram.com/libresse_my/'>@libresse_my</a>) \
              or Libresse Malaysia’s Facebook page (<a href='https://www.facebook.com/LibresseMalaysia/'>@LibresseMalaysia</a>), post special moment of themselves with their mothers (eg: cooking together, travelling together, etc.) and include hashtags #LibresseMY and #MomentsBersamaIbu2023 .</li> \
            <li class='mb-1'>A total of fifty (50) winners shall be selected by the Organiser.</li>"
        },
        {
          title: "4. CONTEST PRIZES",
          text:
            "<ul><li class='mb-1'>Each winner for the Libresse #MomentsBersamaIbu Contest will be entitled to receive <b>1 set of Libresse® #MomentsBersamaIbu Hamper</b>. The selected winners will be announced 4 days after the end of each contest duration stated below:</li> \
            <table style='padding: .5rem;border: 1px solid'><tr><th style='padding: .5rem;border: 1px solid'><b>Duration</b></th><th style='padding: .5rem;border: 1px solid'><b>Number of Winners</b></th><th style='padding: .5rem;border: 1px solid'><b>Items in Hamper</b></th></tr>\
              <tbody><td style='padding: .5rem;border: 1px solid'>2nd May 2023 – 31st October 2023</td><td style='padding: .5rem;border: 1px solid'>50 Winners</td><td style='padding: .5rem;border: 1px solid'>1x Libresse® SensitiV™ Maxi Wings 24cm 16s <br> \
              1x Libresse® SensitiV™ Maxi Night 32cm 12s <br> \
              1x Libresse® SensitiV™ Long & Wide 41cm 6s <br> \
              1x Libresse® SensitiV™ Longer & Wider Slim Pantyliners 30s <br> \
              1x Libresse® Maximum Security Panties 2s <br> \
              1x Libresse® SensitiV™ Hypoallergenic Intimate Wash 80ml <br> \
              1x Libresse® SensitiV™ Hypoallergenic Intimate Wipes <br> \
              1x Libresse® The V Game (Card) <br> \
              </td></tbody></table> \
            <img style='padding:1rem;' src='mom/moment_table.jpg'>\
            <li class='mb-1'>Each Contestant is allowed to win only one (1) prize throughout the Contest Period.</li> \
            <li class='mb-1'>A Contestant is allowed to submit multiple entries for the Contest but only one entry will be considered for the prize in respect of the same Contestant throughout the Contest Period.</li> \
            <li class='mb-1'>The Organiser reserves the right to refuse and/or remove any submissions by the Contestant without prior notice if the contents of the post and/or application is deemed to be inappropriate to the Organiser.</li> \
            <li class='mb-1'>All entries will be judged internally by the Organiser solely. The judging criteria of the Contest are based on eligibility. The Prize winners must meet the contest criteria and will be chosen randomly. The Organiser’s decision is final, and the Organiser will not entertain any written or verbal complaints from Contestant against competing submissions.</li> \
            <li class='mb-1'>All selected Prize winners will be informed and /or contacted via Personal Message on Instagram after announcing on the winner announcement post. The Prize winners need to give their personal details via Personal Message within 1 week after the announcement contact. Otherwise, the organiser reserves the right to select another equivalent winner as replacement. Results announced are final and the Organiser reserves the right to not entertain any written or verbal communication post announcement.</li> \
            <li class='mb-1'>All prizes will be sent by courier/post to the mailing addresses provided by the winners. It is the responsibility of the winner to provide a correct and valid address. Organiser shall not be held liable in the event of non-receipt and/or delayed delivery and/or collection by the winners from the courier company which may cause the product to be faulty/damaged/expired.</li> \
            <li class='mb-1'>The Organiser reserves the right to substitute any prize with that of similar value at any time without prior notice. All prizes are not transferable, refundable, or exchangeable in any other form for whatever reason. The value of the prize is correct at the time of printing. All prizes are given on an “as is” basis.</li> </ul>"
        },
        {
          title: "5. OTHER TERMS AND CONDITIONS",
          text:
            "<ul><li class='mb-1'>By participating in this Contest, participants hereby give consent to provide their personal information and agree for the Organiser to publish, use the participants’ names and/or photographs plus their entries for the purposes of publicity, advertising and/or trade without any compensation or royalty and/or notice and each participant is not entitled to make any claims for the use of their entries by the Organisers.</li> \
            <li class='mb-1'>In administering this Contest, the Organiser may collect personal information, including without limited to, the names and contact information of persons participating in the contest. Any personal information collected by the Organiser in administering this Contest will be managed in accordance with the Malaysian Personal Data Protection Act 2010.</li> \
            <li class='mb-1'>The Organisers may collect personal identification information to provide services and/or to correspond with the participant. This information is stored in a manner that is appropriate to the nature of the data by the Organisers and are used to fulfil the participants’ request(s). By submitting the participants’ personal information, the participants hereby consent to the Organisers and/or its authorised agents to use the information collected for the present and future marketing and promotional purposes and to improve its products and services. If the participant informs the Organiser at <a href='mailto:libressetouch@vinda.com'>libressetouch@vinda.com</a> that the above information should not be used as a basis for further contact, the Organiser shall respect the participant’s request. The participant’s information shall not be provided and/or shared with other companies for their use.</li> \
            <li class='mb-1'>Any names, trademarks or logos used and/or reproduced in any materials (including marketing and promotional materials) in connection with this Contest, particularly that relates to the redemption of prizes, are the properties of their respective third parties involved in the contest. This Contest and its Organiser are not affiliated with or endorsed or sponsored by the relevant respective third parties involved in the contest, unless otherwise communicated, and such respective third parties involved in the contest are not part of the Organisers’ group of companies.</li> \
            <li class='mb-1'>By participating in this Contest, the Contestant agrees to be bound by the official Terms and Conditions and decisions of the Organisers.</li> \
            <li class='mb-1'>The Organiser reserves the right at its sole discretion, to provide an alternative prize of an equivalent value, to amend the prizes based on availability, without prior notice.</li> \
            <li class='mb-1'>The Organiser reserves the right to amend, delete and/or add any further changes to these Terms and Conditions without any prior notice at any time and the participants shall be bound to such changes.</li> \
            <li class='mb-1'>The Organiser shall not be held responsible and/or liable for any liability, mishap, injury, damage, claim and/or accidents [including death] resulting from the participation in this Contest, redemption and/or utilisation of the Prizes by the participants and/or their heir and representatives.</li> \
            <li class='mb-1'>The Organisers’ decision shall be final. Any correspondence pertaining to the decision made by the Organisers will not be entertained.</li> </ul>"
        }
      ],
      // tnc: [
      //   {
      //     title: "1. Penganjur",
      //     text: "Libresse&reg; Malaysia"
      //   },
      //   {
      //     title: "2. Pertandingan",
      //     text:
      //       "Pertandingan Peringkat Kebangsaan “My Best Moment with Mom” 2023"
      //   },
      //   {
      //     title: "3. Hadiah Pertandingan",
      //     text:
      //       "<ul><li class='mb-1'><b>Tempat Pertama:</b> RM300 + Hamper Libresse&reg; + Sijil Pencapaian</li> \
      //       <li class='mb-1'><b>Tempat Ke-2:</b> RM250 + Hamper Libresse&reg; + Sijil Pencapaian</li> \
      //       <li class='mb-1'><b>Tempat Ke-3:</b> RM200 + Hamper Libresse&reg; + Sijil Pencapaian</li> \
      //       <li class='mb-1'><b>Tempat Ke-4 – Ke-10:</b> RM50 Baucar Watson’s barangan Libresse&reg; Malaysia + Hamper Libresse&reg; + Sijil Pencapaian</li></ul>"
      //   },
      //   {
      //     title: "4. Tempoh Pertandingan",
      //     text:
      //       "<p>Pertandingan ini bermula <b>12:00AM pada 25 April 2023</b> hingga <b>11.59PM pada 29 Julai 2023.</b></p>"
      //   },
      //   {
      //     title: "5. Tarikh – Tarikh Penting",
      //     text:
      //       "<ul><li class='mb-1'>Penyertaan dibuka: <b>25 April 2023</b></li> \
      //       <li class='mb-1'>Penyertaan ditutup: <b>29 Julai 2023</b></li> \
      //       <li class='mb-1'>Pengumuman TOP 10 dari setiap negeri: <b>30 Julai 2023 – 6 Ogos 2023</b></li> \
      //       <li class='mb-1'>Penjurian Peringkat Negeri: <b>7 Ogos 2023 – 13 Ogos 2023</b></li> \
      //       <li class='mb-1'>Pengumuman Pemenang TOP 16 Peringkat Negeri: <b>17 Ogos 2023</b></li> \
      //       <li class='mb-1'>Penjurian Peringkat Kebangsaan: <b>22 Ogos – 26 Ogos 2023</b></li> \
      //       <li class='mb-1'>Pengumuman TOP 10 Peringkat Kebangsaan: <b>30 Ogos 2023</b></li> </ul>"
      //   },
      //   {
      //     title: "6. Kelayakan",
      //     text:
      //       "<p>Pertandingan ini terbuka kepada <b>murid perempuan tahun 4, 5 dan 6</b> yang menyertai program e-pembelajaran Libresse Know Your V 2023 sahaja.</p>"
      //   },
      //   {
      //     title: "7. Syarat Penyertaan",
      //     text:
      //       "<ul><li class='mb-1'>Penyertaan adalah percuma.</li> \
      //       <li class='mb-1'>Penyertaan adalah <b>secara individu.</b></li> \
      //       <li class='mb-1'>Setiap peserta hanya layak untuk memenangi satu (1) hadiah sahaja.</li> \
      //       <li class='mb-1'>Kos penyediaan video adalah ditanggung sepenuhnya oleh peserta.</li>\
      //       <li class='mb-1'>Peserta hendaklah mengisi penyertaan dengan lengkap dan berjaya disahkan oleh pihak Penganjur.</li></ul>"
      //   },
      //   {
      //     title: "8. Kaedah Kemasukan",
      //     text:
      //       "<p>Untuk menyertai Pertandingan Peringkat Kebangsaan “My Best Moment with Mom” dan menerima hadiah pertandingan, murid – murid perlu:\
      //           <br>1. Sertai program e-pembelajaran Libresse&reg; Know Your V 2023 dan jawab soalan kuiz di pautan <a href='www.libressemy.com'>www.libressemy.com</a> \
      //           <br>2. Peserta dikehendaki untuk menyediakan sinopsis/ringkasan isi kandungan video dan memuat naik video penyertaan tentang ibu di ruangan yang telah \
      //           disediakan di pautan <a href='www.libressemy.com'>www.libressemy.com</a>\
      //           <br>3. Klik “Hantar” \
      //           <br>4. Pendaftaran mestilah lengkap dan berjaya disahkan oleh pihak Penganjur.\
      //           <br>5. Setiap penyertaan mestilah merupakan kerja asal/asli dan tidak melanggar mana – mana hak cipta orang lain.</p>"
      //   },
      //   {
      //     title: "9. Penggunaan Bahasa",
      //     text:
      //       "<p>Boleh menggunakan <b>Bahasa Malaysia</b> atau <b>Bahasa Inggeris</b></p>"
      //   },
      //   {
      //     title: "10. Format Video",
      //     text:
      //       "<ul><li class='mb-1'>Durasi video hendaklah tidak melebihi dua (2) minit.</li> \
      //       <li class='mb-1'>Hasil karya hendaklah sesuai untuk diterbitkan dan dipapar dalam segala bentuk media video. Format yang dibenarkan adalah MOV dan MP4.</li> \
      //       <li class='mb-1'>Resolusi minimum video yang diterima adalah video HD 720p (1280 x 720-pixel resolution). Kualiti video haruslah jelas di skrin komputer penuh.</li></ul>"
      //   },
      //   {
      //     title: "11. Kriteria Penilaian",
      //     text:
      //       "<p>Setiap video akan dinilai keaslian, mesej/nilai – nilai murni, tema serta kreativitinya oleh panel juri yang dilantik oleh Libresse&reg; Malaysia.\
      //           <br>1. Keaslian (20%) \
      //           <br>2. Mesej dan nilai – nilai murni (30%) \
      //           <br>3. Tema (20%) \
      //           <br>4. Kreativiti (30%) </p>"
      //   }
      // ],
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  methods: {}
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

.moment-container
  width: 95%
  margin: 2rem auto
  padding: 2rem 1rem
  background: white
  text-align: center
  display: flex
  flex-direction: column
  color: $secondary-color
  font-size: 14px

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    margin: auto

  .moment
    width: 33.33%

    @media #{map-get($display-breakpoints, 'sm-and-down')}
      width: 100%

  h3
    font-weight: bolder
    color: $secondary-color

  img
    width: 75%

  *
    margin: auto

.label
  color: $primary-color
  font-size: 24px
  margin-bottom: 2rem

.v-expansion-panels
  margin-bottom: 2rem

  *
    background: transparent !important
    font-size: 16px
    // font-weight: lighter
    color: $secondary-color

  .v-expansion-panel
    margin-bottom: .5rem

  .v-expansion-panel::before
    box-shadow: unset !important

  .v-expansion-panel::after
    border: unset !important

  .v-expansion-panel-header
    font-weight: 500
    *
      font-size: 20px !important
</style>
